(function (factory) {
    typeof define === 'function' && define.amd ? define(['kendo.mobile.scroller', 'kendo.toolbar', 'kendo.combobox', 'kendo.textbox', 'kendo.core', 'kendo.drawing', 'kendo.dialog', 'kendo.window', 'kendo.binder', 'kendo.numerictextbox', 'kendo.dropdownlist', 'kendo.upload'], factory) :
    factory();
})((function () {
    (function($, undefined$1) {
        var extend = $.extend;
        var isLoaded = function() {
            if (!window.pdfjsLib)
            {
                var console = window.console;

                if (console && console.error) {
                    console.error("PDF.JS required.");
                }

                return false;
            }

            kendo.pdfviewer.pdfjs.lib = window.pdfjsLib;

            return true;
        };

        extend(kendo, {
            pdfviewer: {
                pdfjs: {
                    lib: window.pdfjsLib,
                    isLoaded: isLoaded
                }
            }
        });
    })(window.kendo.jQuery);

    var __meta__$2 = {
        id: "pdfjs-processor",
        name: "PDFJS-Processor",
        category: "framework",
        depends: [ "core" ]
    };

    (function($, undefined$1) {
        var kendo = window.kendo,
            Class = kendo.Class,
            extend = $.extend,
            atob = window.atob,
            PDFJS;

        var PDFJSProcessor = Class.extend({
            init: function(options, viewer) {
                var that = this;

                if (kendo.pdfviewer.pdfjs.isLoaded()) {
                    PDFJS = kendo.pdfviewer.pdfjs.lib;
                }

                that.file = options.file;
                that.viewer = viewer;
            },
            fetchDocument: function() {
                var that = this,
                    deferred = $.Deferred(),
                    messages = that.viewer.options.messages.errorMessages;

                if (!that.file) {
                    return deferred.resolve();
                }

                if (that._isBase64Data() && atob)
                {
                    that.file.data = atob(that.file.data);
                }

                PDFJS.getDocument(this.file).promise.then(function(pdf) {
                    var pageSizes = [];
                    that.pdf = pdf;
                    that.pagePromises = [];
                    that._downloadData = $.Deferred();

                    pdf.getData().then(function(data) {
                        var blob = new Blob([data], { type: 'application/pdf' });
                        that._downloadData.resolve({
                            file: blob
                        });
                    });

                    for (var i = 1; i <= pdf.numPages; i++) {
                        that.pagePromises.push(pdf.getPage(i));
                    }

                    Promise.all(that.pagePromises).then(function(pagePromises) {
                        pageSizes = pagePromises.map(function(pagePromise) {
                            var viewport = pagePromise.getViewport({ scale: 4 / 3 });
                            return {
                                width: viewport.width,
                                height: viewport.height
                            };
                        });

                        deferred.resolve({
                            total: pdf.numPages,
                            pages: pageSizes
                        });
                    }).catch(function(e) {
                        that.viewer._triggerError({
                            error: e.message,
                            message: messages.parseError
                        });
                    });

                }).catch(function(e) {
                    var notFoundError = e.name.includes("Missing");
                    var alertMessage = notFoundError ? messages.notFound : messages.parseError;
                    that.viewer._triggerError({
                        error: e.message,
                        message: alertMessage
                    });
                    if (notFoundError) {
                        that.viewer._renderBlankPage();
                    }
                });

                return deferred;
            },
            fetchPageData: function(number) {
                return this.pagePromises[number - 1];
            },
            downloadFile: function(fileName) {
                var that = this;
                kendo.ui.progress(that.viewer.pageContainer, true);

                that._downloadData.done(function(result) {
                    kendo.ui.progress(that.viewer.pageContainer, false);

                    var reader = new FileReader();
                    reader.readAsDataURL(result.file);

                    reader.onload = function() {
                        kendo.saveAs({
                            dataURI: reader.result,
                            fileName: fileName + ".pdf",
                            proxyURL: function() {
                                return reader.result;
                            }
                        });
                    };
                });
            },
            _updateDocument: function(file) {
                if (this.pdf && this.pdf.loadingTask) {
                    this.pdf.loadingTask.destroy();
                }

                this.file = file;
            },
            _isBase64Data: function() {
                var data = this.file.data,
                    notBase64 = /[^A-Z0-9+\/=]/i,
                    length = data && data.length,
                    equalSign;

                if (!length || length % 4 !== 0 || notBase64.test(data)) {
                    return false;
                }

                equalSign = data.indexOf('=');

                return equalSign === -1 ||
                    equalSign === length - 1 ||
                    (equalSign === length - 2 && data[length - 1] === '=');
            },
            renderTextLayer: function(params) {
                PDFJS.renderTextLayer(params);
            }
        });

        extend(kendo.pdfviewer.pdfjs, {
            processor: PDFJSProcessor
        });
    })(window.kendo.jQuery);

    var __meta__$1 = {
        id: "dpl-processor",
        name: "DPL-Processor",
        category: "framework",
        depends: [ "core" ]
    };

    (function($, undefined$1) {
        var kendo = window.kendo,
            extend = $.extend,
            Class = kendo.Class;

        var DPLProcessor = Class.extend({
            init: function(options, viewer) {
                var that = this;

                that.options = options;
                that.read = options.read;
                that.upload = options.upload;
                that.download = options.download;

                that.viewer = viewer;
            },
            fetchDocument: function() {
                var that = this,
                    deferred = $.Deferred(),
                    errorMessages = that.viewer.options.messages.errorMessages;

                if (!that.read) {
                    return deferred.resolve();
                }

                $.ajax({
                    type: that.read.type,
                    url: that.read.url,
                    dataType: that.read.dataType,
                    success: function(data) {
                        if (typeof data != "string") {
                            data = kendo.stringify(data);
                        }
                        deferred.resolve(JSON.parse(data));
                    },
                    error: function(xhr) {
                        that.viewer._triggerError({
                            error: xhr.responseText,
                            message: errorMessages.parseError
                        });
                    }
                });

                return deferred;
            },
            fetchPageData: function(number) {
                var that = this;
                var deferred = $.Deferred();
                var page = that.viewer.document.pages[number - 1];
                var data = {};
                data[that.read.pageField] = number;

                if (!page.geometries.length) {
                    $.ajax({
                        type: that.read.type,
                        url: that.read.url,
                        data: data,
                        success: function(data) {
                            deferred.resolve(JSON.parse(data));
                        },
                        error: function(xhr) {
                            that.viewer._triggerError({
                                error: xhr.responseText,
                                message: that.viewer.options.messages.errorMessages.parseError
                            });
                        }
                    });
                } else {
                    deferred.resolve(page);
                }

                return deferred;
            },
            downloadFile: function(fileName) {
                window.location = this.download.url + "?file=" + fileName;
            },

            fromJSON: function(json)
            {
                var viewer = this.viewer;
                viewer._clearPages();

                viewer.document = json;
                viewer.document.total = viewer.document.pages.length;

                viewer._renderPages();
                viewer.resize(true);

                viewer.activatePage(1);
            }
        });

        extend(kendo.pdfviewer, {
            dpl: {
                processor: DPLProcessor
            }
        });
    })(window.kendo.jQuery);

    (function($, undefined$1) {
        var extend = $.extend,
            noop = $.noop,
            drawing = kendo.drawing,
            Group = drawing.Group,
            Surface = drawing.Surface,
            RENDER = "render",
            Class = kendo.Class,

            DEFAULT_DPR = 2;

        var geometryTypes = {
            Path: "path",
            MultiPath: "multipath",
            Rect: "rect",
            Image: "image",
            Text: "text"
        };

        var Page = Class.extend({
            init: function(options, viewer) {
                this.viewer = viewer;
                this.processor = options.processor;
                this.options = options;
                this.pageNumber = options.number;

                this.element = $("<div class='k-page' />");
                this.element.attr(kendo.attr("number"), this.pageNumber);

                this._updatePageSize(options);
                this.width = options.width;
                this.height = options.height;
            },
            resize: function(ratio) {
                var pageElement = this.element;

                this._updatePageSize({
                    width: Math.min(pageElement.width() * ratio, this.width),
                    height: Math.min(pageElement.height() * ratio, this.height)
                });
            },
            _updatePageSize: function(size) {
                this.element
                        .width(size.width)
                        .height(size.height);
            },
            destroy: function() {
                kendo.destroy(this.element);
            },
            render: noop
        });

        var DPLPage = Page.extend({
            draw: function() {
                var that = this,
                    geometries = that.options.geometries;

                that.group = new Group();
                that.surface.draw(that.group);

                that._drawGeometries(geometries);

                that.viewer.trigger(RENDER, { page: this });
                kendo.ui.progress(that.element, false);
            },
            load: function() {
                var that = this;

                if (that.loaded || !that.processor)
                {
                    return;
                }

                that.processor.fetchPageData(that.pageNumber).then(function(data) {
                    that.options = data;
                    that._initSurface();
                    that.draw();
                });

                that.loaded = true;
            },
            _initSurface: function() {
                var size = {
                    width: this.element.width(),
                    height: this.element.height()
                };
                var surfaceOptions = extend({ width: this.width, height: this.height }, this.viewer.options.view);
                this.surface = new Surface(this.element, surfaceOptions);
                this._updatePageSize(size);
            },
            _drawGeometries: function(geometries) {
                var that = this,
                    kGeometry;

                if (!geometries) {
                    return;
                }

                for (var i = 0; i <= geometries.length; i++) {
                    var geometry = geometries[i];

                    if (!geometry) {
                        continue;
                    }

                    switch (geometry.type) {
                        case geometryTypes.Path:
                        case geometryTypes.MultiPath:
                            kGeometry = that._drawPath(geometry);
                            break;
                        case geometryTypes.Rect:
                            kGeometry = that._drawRect(geometry);
                            break;
                        case geometryTypes.Image:
                            kGeometry = that._drawImage(geometry);
                            break;
                        case geometryTypes.Text:
                            kGeometry = that._drawText(geometry);
                            break;
                        default:
                            kGeometry = null;
                            break;
                    }

                    if (kGeometry)
                    {
                        that.group.append(kGeometry);
                    }
                }
            },
            _drawRect: function(geometry)
            {
                var rectGeo = new kendo.geometry.Rect(geometry.point, geometry.size);

                return new drawing.Rect(rectGeo, {
                    transform: this._getMatrix(geometry.transform),
                    fill: geometry.fillOptions,
                    stroke: geometry.strokeOptions
                });
            },

            _drawImage: function(geometry)
            {
                var imageRect = new kendo.geometry.Rect(geometry.point, geometry.size);
                return new drawing.Image(geometry.src, imageRect, {
                    transform: this._getMatrix(geometry.transform)
                });
            },

            _drawText: function(geometry)
            {
                var options = {
                    transform: this._getMatrix(geometry.transform),
                    stroke: geometry.strokeOptions,
                    fill: geometry.fillOptions,
                    font: geometry.font
                };
                return new kendo.drawing.Text(geometry.content, geometry.point, options);
            },

            _drawPath: function(geometry)
            {
                var options = {
                    transform: this._getMatrix(geometry.transform),
                    stroke: geometry.strokeOptions,
                    fill: geometry.fillOptions
                };
                var path = new drawing.MultiPath(options);

                for (var i = 0; i < geometry.paths.length; i++) {
                    var subPath = geometry.paths[i];

                    if (!subPath.segments)
                    {
                        return;
                    }

                    path.moveTo.apply(path, subPath.point);

                    for (var j = 0; j < subPath.segments.length; j++) {
                        var segment = subPath.segments[j];
                        var drawAction = segment.points.length === 1 ? path.lineTo : path.curveTo;
                        drawAction.apply(path, segment.points);
                    }

                    if (subPath.closed) {
                        path.close();
                    }
                }

                return path;
            },

            _getMatrix: function(transform) {
                var matrix = Object.create(kendo.geometry.Matrix.prototype);
                kendo.geometry.Matrix.apply(matrix, transform);
                return matrix;
            }
        });

        var PDFJSPage = Page.extend({
            init: function(options, viewer) {
                var that = this,
                    canvas;

                canvas = $("<canvas style='width: 100%; height: 100%;' />");
                that.canvas = canvas.get(0);

                Page.fn.init.call(that, options, viewer);
                that.canvas.width = that.width;
                that.canvas.height = that.height;

                that.element.append(canvas);
            },
            load: function(defaultScale, force) {
                var that = this,
                    promise = $.Deferred();

                if (that._scale === defaultScale && !force)
                {
                    return;
                } else if (that._scale && that._scale !== defaultScale && !force)
                {
                    that._scale = defaultScale;
                    that.render(defaultScale);
                    return promise.resolve(that);
                }

                if (that.processor) {
                    that.processor.fetchPageData(that.pageNumber).then(function(page) {
                        that._page = page;
                        that._renderPromise = that.render(defaultScale).then(function() {
                            that.viewer.trigger(RENDER, { page: that });
                        });
                        promise.resolve(that);
                    });
                }

                that._scale = defaultScale;
                that.loaded = true;
                return promise;
            },
            render: function(scale) {
                var that = this;
                var dpr = window.devicePixelRatio >= DEFAULT_DPR ? window.devicePixelRatio : DEFAULT_DPR;
                var context = this.canvas.getContext('2d'),
                    viewport = this._page.getViewport({
                        scale: scale
                    });

                this.canvas.width = viewport.width * dpr;
                this.canvas.height = viewport.height * dpr;
                context.scale(dpr, dpr);

                this._scale = scale;
                this._dpr = dpr;

                if (this._renderTask)
                {
                    this._renderTask.cancel();
                    this._renderTask = null;
                }

                this._updatePageSize({
                    width: viewport.width,
                    height: viewport.height
                });

                this._renderTask = this._page.render({
                    canvasContext: context,
                    viewport: viewport
                });

                this._renderTextLayer(viewport);

                return this._renderTask.promise.then(function() {
                    that._renderTask = null;

                }).catch(function() {});
            },
            _renderTextLayer: function(viewport) {
                var that = this;
                var page = that._page;

                if (that.textLayer) {
                    that.textLayer.remove();
                 }

                that.textLayer = $("<div class='k-text-layer'></div>").get(0);
                that.element.append(that.textLayer);

                page.getTextContent({
                    normalizeWhitespace: true
                }).then(function(textContent) {
                    $(that.textLayer).css({
                      height: viewport.height,
                      width: viewport.width
                    }).html(""); // Clear content to make sure that refreshing the page will not cause duplication of the text content.

                    var params = {
                        textContent: textContent,
                        container: that.textLayer,
                        viewport: viewport,
                        textDivs: [],
                        enhanceTextSelection: true
                    };

                    that.processor.renderTextLayer(params);
                });
            }
        });

        extend(kendo.pdfviewer.dpl, {
            geometryTypes: geometryTypes,
            Page: DPLPage
        });
        extend(kendo.pdfviewer.pdfjs, {
            Page: PDFJSPage
        });
    })(window.kendo.jQuery);

    (function($, undefined$1) {
        var Class = kendo.Class,
            extend = $.extend,
            isArray = Array.isArray;

        var SearchDOM = Class.extend({
            init: function(options) {
                var that = this;

                that.options = extend({}, that.options, options);

                that.processDom();
            },

            options: {
                highlightClass: "k-search-highlight",
                charClass: "k-text-char"
            },

            processDom: function() {
                var that = this;

                that.targets = isArray(that.options.target) ? that.options.target : [that.options.target];
                that.textNodes = [];
                that.charIndex = 0;
                that.text = "";

                that.targets.forEach(function(target) {
                    that.traverseToTextNode(target);
                });

                for (var i = 0; i < that.textNodes.length; i++) {
                    that.processTextNode(that.textNodes[i]);
                }
            },

            traverseToTextNode: function(node) {
                var that = this;

                if (node.nodeType === 3) {
                    that.textNodes.push(node);
                } else {
                    for (var i = 0; i < node.childNodes.length; i++) {
                        that.traverseToTextNode(node.childNodes[i]);
                    }
                }
            },

            processTextNode: function(node) {
                var that = this;
                var text = node.textContent;
                var span;

                that.text = that.text + text;

                if (text.length > 0) {
                    span = $(node).wrap("<span>").parent();
                    span.empty();
                    that.splitChars(span.get(0), text);
                    span.children().unwrap();
                }
            },

            splitChars: function(span, text) {
                var that = this;
                var newHtml = "";

                for (var i = 0; i < text.length; i++) {
                    newHtml = newHtml + "<span class='" + that.options.charClass + "' " + kendo.attr("char-index") + "=" + that.charIndex + ">" + text[i] + "</span>";
                    that.charIndex++;
                }

                span.innerHTML = newHtml;
            },

            search: function(value, matchCase) {
                var that = this;
                var expression = new RegExp(value, !matchCase ? "gi" : "g");
                var match;

                that.matches = [];

                that.resetMark();
                that.resetHighlight();
                that.resetMatchIndex();

                if (value === "") {
                    return;
                }

                match = expression.exec(that.text);

                while (match) {
                    that.matches.push({
                        startOffset: match.index,
                        endOffset: match.index + match[0].length
                    });

                    match = expression.exec(that.text);
                }

                that.highlightAll();
                that.mark();
            },

            highlightAll: function() {
                var that = this;

                that.matches.forEach(function(match, index) {
                    var start = match.startOffset;
                    var end = match.endOffset;

                    that.highlight(start, end, index + 1);
                });
            },

            highlight: function(start, end, matchIndex) {
                var that = this;

                for (var i = start; i < end; i++) {
                    $(that.targets)
                        .find("." + that.options.charClass + "[" + kendo.attr("char-index") + "=" + i + "]")
                        .addClass(that.options.highlightClass)
                        .attr(kendo.attr("match-index"), matchIndex);
                }
            },

            resetHighlight: function() {
                var that = this;

                $(that.targets)
                    .find("." + that.options.highlightClass)
                    .removeClass(that.options.highlightClass);
            },

            resetMatchIndex: function() {
                var that = this;

                $(that.targets)
                    .find("." + that.options.charClass + "[" + kendo.attr("match-index") + "]")
                    .removeAttr(kendo.attr("match-index"));
            },

            mark: function() {
                var that = this;

                if (!that.currentIndex && that.currentIndex !== 0) {
                    that.currentIndex = 0;
                } else if (that.currentIndex > that.matches.length) {
                    that.currentIndex = that.matches.length;
                } else {
                    that.resetMark();
                }

                $(that.targets)
                    .find("." + that.options.charClass + "[" + kendo.attr("match-index") + "=" + that.currentIndex + "]")
                    .wrapInner("<mark>");
            },

            resetMark: function() {
                var that = this;
                $(that.targets).find("mark").contents().unwrap();
            },

            nextMatch: function() {
                var that = this;

                that.currentIndex++;

                if (that.currentIndex > that.matches.length) {
                    that.currentIndex = 1;
                }

                that.mark();
            },

            previousMatch: function() {
                var that = this;

                that.currentIndex--;

                if (that.currentIndex < 1) {
                    that.currentIndex = that.matches.length;
                }

                that.mark();
            },

            getMarkedIndex: function() {
                return this.matches.length ? this.currentIndex : 0;
            },

            getFirstMarked: function() {
                return $(this.targets).find("mark").eq(0);
            },

            destroy: function() {
                var that = this;

                that.resetMark();
                $(that.targets).children("span:not(." + that.options.charClass + ")").each(function(i, item) {
                    $(item).text($(item).text());
                });
            }
        });

        extend(kendo.pdfviewer, {
            SearchDOM: SearchDOM
        });
    })(window.kendo.jQuery);

    (function($, undefined$1) {
        var kendo = window.kendo,
            encode = kendo.htmlEncode,
            extend = $.extend,
            Class = kendo.Class,
            EXTENSIONS = {
                svg: ".svg",
                png: ".png"
            },
            keys = kendo.keys;

        var ErrorDialog = Class.extend({
            init: function(options) {
                this.options = extend(options, {
                    actions: [{
                        text: options.messages.dialogs.okText
                    }]
                });
                this._dialog = $("<div />")
                        .kendoDialog(this.options)
                        .getKendoDialog();
            },
            open: function() {
                this._dialog.center().open();
            }
        });

        var ExportAsDialog = Class.extend({
            init: function(options) {
                this.options = extend(options, this.options, {
                    fileFormats: [{
                        description: options.messages.dialogs.exportAsDialog.png,
                        extension: EXTENSIONS.png
                    }, {
                        description: options.messages.dialogs.exportAsDialog.svg,
                        extension: EXTENSIONS.svg
                    }],
                    title: options.messages.dialogs.exportAsDialog.title,
                    open: function() {
                        this.center();
                    }
                });
                this._initializeDialog();
                return this;
            },
            options: {
                extension: EXTENSIONS.png,
                autoFocus: true,
                resizable: false,
                modal: {
                    preventScroll: true
                },
                width: "90%",
                maxWidth: 520,
                template: function (ref) {
                        var messages = ref.messages;
                        var total = ref.total;

                        return "<div class='k-edit-label'><label>" + (encode(messages.exportAsDialog.labels.fileName)) + ":</label></div>" +
                    "<div class='k-edit-field'>" +
                        "<span class='k-textbox k-input k-input-md k-rounded-md k-input-solid'><input class='k-input-inner' data-bind='value: name' /></span>" +
                    "</div>" +
                    "<div>" +
                        "<div class='k-edit-label'><label>" + (encode(messages.exportAsDialog.labels.saveAsType)) + ":</label></div>" +
                        "<div class='k-edit-field'>" +
                        "<select data-role='dropdownlist' class='k-file-format' " +
                            "data-text-field='description' " +
                            "data-value-field='extension' " +
                            "data-bind='value: extension, source: fileFormats'></select>" +
                        "</div>" +
                    "</div>" +
                    "<div class='k-edit-label'><label>" + (encode(messages.exportAsDialog.labels.page)) + ":</label></div>" +
                    "<div class='k-edit-field'>" +
                        "<input data-role='numerictextbox' data-format='n0' data-min='1' data-max='" + (encode(total)) + "' data-bind='value: page' />" +
                    "</div>" +
                    "<div class='k-action-buttons'>" +
                        "<button class='k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary' data-bind='click: apply'><span class='k-button-text'>" + (encode(messages.save)) + "</span></button>" +
                        "<button class='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base' data-bind='click: close'><span class='k-button-text'>" + (encode(messages.cancel)) + "</span></button>" +
                    "</div>";
        }
            },
            _updateModel: function(options) {
                if (options.pagesCount) {
                    this.viewModel.set("pagesCount", options.pagesCount);
                }
                if (options.page) {
                    this.viewModel.set("page", options.page);
                }
            },
            _initializeDialog: function() {
                var that = this;
                var options = that.options;
                var dialogMessages = options.messages.dialogs;
                var dialog = $("<div class='k-pdf-viewer-window k-action-window k-popup-edit-form' />")
                        .append(kendo.template(options.template)({
                            total: options.pagesCount,
                            messages: dialogMessages
                        }))
                        .kendoWindow(options)
                        .getKendoWindow();

                that.viewModel = kendo.observable({
                    title: dialogMessages.exportAsDialog.title,
                    name: dialogMessages.exportAsDialog.defaultFileName,
                    extension: options.extension,
                    fileFormats: options.fileFormats,
                    pagesCount: options.pagesCount,
                    page: 1,
                    apply: that.apply.bind(this),
                    close: function() {
                        dialog.close();
                    }
                });

                that._dialog = dialog;

                kendo.bind(dialog.element, that.viewModel);
                return dialog;
            },
            open: function() {
                this._dialog.center().open();
            },
            apply: function() {
                this._dialog.close();
                this.options.apply({
                    fileName: this.viewModel.name + this.viewModel.extension,
                    extension: this.viewModel.extension,
                    page: this.viewModel.page
                });
            }
        });

        var SearchDialog = Class.extend({
            init: function(options) {
                var that = this;
                that.options = extend({}, options, that.options);
            },
            options: {
                resizable: false,
                template: function (ref) {
                    var messages = ref.messages;

                    return "<div class='k-search-container'>" +
                              "<button aria-label='" + (encode(messages.dragHandle)) + "' class='k-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-icon-button k-search-dialog-draghandle'><span class='k-button-icon k-icon k-i-handler-drag'></span></button>" +
                              "<span class='k-textbox k-input k-input-md k-rounded-md k-input-solid'>" +
                                  "<input class='k-search-dialog-input k-input-inner' data-bind='value: boundValue, events: { keyup: onKeyup, input: onInput }' aria-label='" + (encode( messages.inputLabel)) + "' title='" + (encode(messages.inputLabel)) + "' />" +
                                  "<span class='k-input-suffix'><button class='k-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-icon-button k-match-case-button k-match-case-button' data-bind='css: {k-selected: matchCase}, click: matchCaseClick' aria-label='" + (encode(messages.matchCase)) + "' title='" + (encode(messages.matchCase)) + "'><span class='k-icon k-i-convert-lowercase'></span></button></span>" +
                              "</span>" +
                              "<span class='k-search-matches'><span data-bind='text: matchIndex'></span> " + (encode(messages.of)) + " <span data-bind='text: matches'></span></span>" +
                              "<button class='k-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-icon-button' data-bind='click: prev' aria-label='" + (encode(messages.previous)) + "' title='" + (encode(messages.previous)) + "'><span class='k-button-icon k-icon k-i-arrow-up'></span></button>" +
                              "<button class='k-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-icon-button' data-bind='click: next' aria-label='" + (encode(messages.next)) + "' title='" + (encode(messages.next)) + "'><span class='k-button-icon k-icon k-i-arrow-down'></span></button>" +
                              "<button class='k-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-icon-button' data-bind='click: close' aria-label='" + (encode(messages.close)) + "' title='" + (encode(messages.close)) + "'><span class='k-button-icon k-icon k-i-close'></<span></button>" +
                          "</div>";
        }
            },
            open: function() {
                var that = this;

                if (!that.dialog) {
                    that._initializeDialog();
                }

                that.dialog.open();
            },
            _initializeDialog: function() {
                var that = this;
                var template = kendo.template(that.options.template);
                var dialogElm = $("<div class='k-pdf-viewer-search-dialog'></div>").append(template({
                    messages: that.options.messages
                }));
                var dialogOffset = {
                    top: that.options.position.top + 16,
                    left: that.options.position.left + 16
                };

                that.dialog = new kendo.ui.Window(dialogElm, extend({}, that.options, {
                    autoFocus: false,
                    title: false,
                    position: { top: dialogOffset.top, left: dialogOffset.left },
                    minHeight: 30,
                    draggable: {
                        dragHandle: ".k-search-dialog-draghandle"
                    },
                    activate: function(ev) {
                        ev.sender.element.find(".k-search-dialog-input").trigger("focus");
                    }
                }));

                that.searchModel = kendo.observable({
                    boundValue: "",
                    searchText: "",
                    matchCase: false,
                    matchIndex: 0,
                    matches: 0,
                    matchCaseClick: function() {
                        this.set("matchCase", !this.matchCase);
                    },
                    next: that.options.next,
                    prev: that.options.prev,
                    close: function() {
                        this.set("boundValue", "");
                        that.dialog.close();
                    },
                    onKeyup: function(ev) {
                        var key = ev.keyCode;
                        var navigationFn = ev.shiftKey ? this.prev : this.next;

                        if (key === keys.ENTER) {
                            navigationFn();
                            ev.preventDefault();
                        }
                    },
                    onInput: function(ev) {
                        this.set("searchText", ev.target.value);
                    }
                });

                kendo.bind(that.dialog.element, that.searchModel);
            }
        });

        extend(kendo.pdfviewer, {
            dialogs: {
                ErrorDialog: ErrorDialog,
                ExportAsDialog: ExportAsDialog,
                SearchDialog: SearchDialog
            }
        });
    })(window.kendo.jQuery);

    (function($, undefined$1) {
        var kendo = window.kendo,
            extend = $.extend,
            parseJSON = JSON.parse,
            progress = kendo.ui.progress,
            Class = kendo.Class,
            OPEN = "open",
            ZOOMSTART = "zoomStart",
            ZOOMEND = "zoomEnd";

        var Command = Class.extend({
            init: function(options) {
                this.options = options;
                this.viewer = options.viewer;
                this.errorMessages = this.viewer.options.messages.errorMessages;
            }
        });

        var OpenCommand = Command.extend({
            init: function(options) {
                Command.fn.init.call(this, options);
                this.upload = this.viewer.processor.upload;
            },
            exec: function() {
                (this.viewer._upload || this._initUpload()).element.click();
            },
            _initUpload: function() {
                var uploadOptions = {
                    select: this._onSelect.bind(this),
                    success: this._onSuccess.bind(this),
                    error: this._onError.bind(this),
                    complete: this._onComplete.bind(this),
                    showFileList: false,
                    multiple: false,
                    validation: {
                        allowedExtensions: [".pdf"]
                    }
                };

                if (this.upload) {
                    extend(uploadOptions, {
                        async: {
                            saveUrl: this.upload.url,
                            autoUpload: true,
                            saveField: this.upload.saveField
                        }
                    });
                }

                var upload = $('<input name="files" accept=".pdf" type="file" />').kendoUpload(uploadOptions).getKendoUpload();
                this.viewer._upload = upload;

                return upload;
            },
            _onComplete: function() {
                progress(this.viewer.pageContainer, false);
            },
            _onSuccess: function(e) {
                var json = parseJSON(e.response);

                if ($.isPlainObject(json)) {
                    this.viewer.processor.fromJSON(json);
                }
                else {
                    this.viewer._triggerError({
                        error: json,
                        message: this.errorMessages.parseError
                    });
                }
            },
            _onError: function(e) {
                this.viewer._triggerError({
                    error: e.XMLHttpRequest.responseText,
                    message: this.errorMessages.notSupported
                });
            },
            _onSelect: function(e) {
                var that = this;
                var fileToUpload = e.files[0];

                progress(that.viewer.pageContainer, true);

                if (that.viewer.trigger(OPEN, { file: fileToUpload }) || that.upload) {
                    return;
                } else if (fileToUpload.extension.toLowerCase() !== ".pdf") {
                    that.viewer._triggerError({
                        error: fileToUpload,
                        message: that.errorMessages.notSupported
                    });
                    return;
                }

                var reader = new FileReader();
                reader.onload = function(e) {
                    var document = e.target.result;
                    that.viewer.fromFile(document);
                };
                reader.onerror = function() {
                    that.viewer._triggerError({
                        error: fileToUpload,
                        message: that.errorMessages.parseError
                    });
                };

                reader.readAsArrayBuffer(fileToUpload.rawFile);
            }
        });

        var PageChangeCommand = Command.extend({
            exec: function() {
                var targetPage = this.options.value,
                    viewer = this.viewer,
                    current, total;

                if (isNaN(targetPage)) {
                    current = viewer._pageNum;
                    total = viewer.document.total;

                    switch (targetPage) {
                        case "first": targetPage = 1;
                            break;
                        case "prev": targetPage = current > 1 ? current - 1 : 1;
                            break;
                        case "next": targetPage = current < total ? current + 1 : total;
                            break;
                        case "last": targetPage = total;
                            break;
                    }
                } else {
                    targetPage = Number(targetPage);
                }

                viewer.activatePage(targetPage);
            }
        });

        var DownloadCommand = Command.extend({
            exec: function() {
                if (!this.viewer.document) {
                    this.viewer._triggerError({
                        message: this.errorMessages.notFound
                    });
                    return;
                }

                var fileName = (this.viewer.document.info && this.viewer.document.info.title) ||
                                this.viewer.options.messages.defaultFileName;

                this.viewer.processor.downloadFile(fileName);
            }
        });

        var ExportCommand = Command.extend({
            init: function(options) {
                options = $.extend(options, this.options);
                Command.fn.init.call(this, options);
            },
            exec: function() {
                var dialog = (this.viewer._saveDialog || this._initDialog());

                dialog._updateModel({
                    pagesCount: (this.viewer.document && this.viewer.document.total) || 1,
                    page: this.viewer.options.page
                });

                dialog.open();
            },
            apply: function(viewModel) {
                var extension = viewModel.extension;

                if (extension === ".png") {
                    this.viewer.exportImage(viewModel);
                } else if (extension === ".svg") {
                    this.viewer.exportSVG(viewModel);
                }
            },
            _initDialog: function() {
                this.viewer._saveDialog = new kendo.pdfviewer.dialogs.ExportAsDialog({
                    apply: this.apply.bind(this),
                    pagesCount: (this.viewer.document && this.viewer.document.total) || 1,
                    messages: this.viewer.options.messages
                });
                return this.viewer._saveDialog;
            }
        });

        var EnableSelectionCommand = Command.extend({
            exec: function() {
                var that = this,
                    viewer = that.viewer;

                    viewer._toggleSelection(true);
            }
        });

        var EnablePanCommand = Command.extend({
            exec: function() {
                var that = this,
                    viewer = that.viewer;

                    viewer._toggleSelection(false);
            }
        });

        var OpenSearchCommand = Command.extend({
            init: function(options) {
                var that = this;

                that.viewer = options.viewer;

                if (!that.viewer.searchDialog) {
                    that.viewer.searchDialog = new kendo.pdfviewer.dialogs.SearchDialog({
                        position: {
                            top: that.viewer.pageContainer.offset().top,
                            left: that.viewer.pageContainer.offset().left
                        },
                        messages: that.viewer.options.messages.dialogs.search,
                        open: that._open.bind(that),
                        next: that._next.bind(that),
                        prev: that._prev.bind(that),
                        close: that._close.bind(that)
                    });
                }

                Command.fn.init.call(that, options);
            },
            exec: function() {
                var that = this;

                that.viewer.searchDialog.open();
            },
            _open: function() {
                var that = this;

                that.changeHandler = that._change.bind(that);
                that.zoomStartHandler = that._closeDialog.bind(that);
                that.openFileHandler = that._closeDialog.bind(that);

                if (!that.viewer._searchDOM) {
                    that.viewer._initSearchDOM();
                    that.viewer.searchDialog.searchModel.bind("change", that.changeHandler);
                    that.viewer.bind("zoomStart", that.zoomStartHandler);
                    that.viewer.bind("open", that.openFileHandler);
                }
            },
            _close: function() {
                var that = this;
                var searchEngine = that.viewer._searchDOM;

                that.viewer.searchDialog.searchModel.unbind("change", that.changeHandler);
                that.viewer.unbind("zoomStart", that.zoomStartHandler);
                that.viewer.unbind("open", that.openFileHandler);
                searchEngine.destroy();
                delete that.viewer._searchDOM;
                that._updateSearchModel();
                that.viewer.toolbar.element.find("[tabindex=0]").trigger("focus");
            },
            _change: function(ev) {
                var that = this;
                var searchEngine = that.viewer._searchDOM;
                var field = ev.field;
                var model = that.viewer.searchDialog.searchModel;
                var value = model[field];

                if (!searchEngine) {
                    return;
                }

                switch (field) {
                    case "searchText":
                        searchEngine.search(value, model.matchCase);
                        that._updateSearchModel();
                        break;
                    case "matchCase":
                        searchEngine.search(model.searchText, value);
                        that._updateSearchModel();
                        break;
                    default:
                        break;
                }
            },
            _next: function() {
                var that = this;
                var searchEngine = that.viewer._searchDOM;

                if (searchEngine.matches && searchEngine.matches.length) {
                    searchEngine.nextMatch();
                    that._updateSearchModel();
                }
            },
            _prev: function() {
                var that = this;
                var searchEngine = that.viewer._searchDOM;

                if (searchEngine.matches && searchEngine.matches.length) {
                    searchEngine.previousMatch();
                    that._updateSearchModel();
                }
            },
            _updateSearchModel: function() {
                var that = this;
                var searchEngine = that.viewer._searchDOM;
                var model = that.viewer.searchDialog.searchModel;

                if (searchEngine) {
                    model.set("matches", searchEngine.matches.length);
                    model.set("matchIndex", searchEngine.getMarkedIndex());
                    that._scrollToMark();
                } else {
                    model.set("searchText", "");
                    model.set("matches", 0);
                    model.set("matchIndex", 0);
                    model.set("matchCase", false);
                }
            },
            _scrollToMark: function() {
                var that = this;
                var searchEngine = that.viewer._searchDOM;
                var marked = searchEngine.getFirstMarked();
                var scroller = that.viewer._scroller;
                var position;

                if (!marked.length) {
                    return;
                }

                position = marked.offset().top - scroller.scrollElement.offset().top - 100;

                scroller.scrollTo(scroller.scrollLeft, position * -1);
            },
            _closeDialog: function() {
                var that = this;
                that.viewer.searchDialog.dialog.close();
            }
        });

        var ZoomCommand = Command.extend({
            exec: function() {
                var that = this,
                    options = that.options,
                    viewer = that.viewer,
                    scale = options.value || options.scale,
                    loadedPagesHeight = 0,
                    page = that.viewer._pageNum,
                    containerHeight = viewer.pageContainer[0].clientHeight,
                    updatedVisiblePagesCount = 1,
                    renderTasks = [];

                if (viewer.processingLib === "dpl") {
                    return;
                }

                scale = that._calculateZoom();

                var updateViewer = function() {
                    var scroller = that.viewer._scroller,
                        scrollingStarted = viewer._scrollingStarted;

                    if (scroller && scroller.scrollTop > scroller.scrollHeight()) {
                        scroller._resize();
                    }

                    if (!scrollingStarted)
                    {
                        viewer.activatePage(page);
                        viewer._scrollingStarted = false;
                    }
                };

                if (!scale) {
                    return;
                }

                viewer.zoomScale = scale;
                viewer._scrollingStarted = false;

                if (viewer.pages) {
                    viewer.pages.forEach(function(page) {
                        var pageHeight;

                        if (viewer._visiblePages.indexOf(page) !== -1 && page.loaded) {
                            renderTasks.push(page.render(scale));

                            pageHeight = page._page.getViewport({
                                scale: scale
                            }).height;
                        }
                        else {
                            page.resize(scale);
                            pageHeight = page.element.height();
                        }

                        loadedPagesHeight += pageHeight;

                        if (loadedPagesHeight <= containerHeight) {
                            updatedVisiblePagesCount++;
                        }
                    });


                    if (viewer._visiblePagesCount != updatedVisiblePagesCount) {

                        viewer._visiblePagesCount = updatedVisiblePagesCount;
                        viewer._loadVisiblePages();
                    }
                }

                Promise.all(renderTasks).then(function() {
                    updateViewer();
                    that._triggerZoomEnd(scale);
                }).catch(function() {
                    updateViewer();
                    that._triggerZoomEnd(scale);
                });
            },

            _calculateZoom: function() {
                var options = this.options,
                    viewer = this.viewer,
                    viewerOptions = viewer.options,
                    pageContainer = viewer.pageContainer,
                    visibleCanvas = viewer._visiblePages && viewer._visiblePages[0].canvas,
                    calculatedDpr = (viewer._visiblePages && viewer._visiblePages[0]._dpr) || 2,
                    scale = options.value || options.scale,
                    scaleValue = scale,
                    preventZoom;

                viewer._allowResize = false;
                viewer._autoFit = false;

                if (options.zoomIn) {
                    scaleValue = scale = viewer.zoomScale + viewerOptions.zoomRate;
                } else if (options.zoomOut) {
                    scaleValue = scale = viewer.zoomScale - viewerOptions.zoomRate;
                } else if (scale === "auto") {
                    viewer._allowResize = true;
                    scaleValue = viewer._autoZoomScale;
                } else if (scale === "actual") {
                    scaleValue = 1;
                } else if (scale === "fitToWidth") {
                    viewer._allowResize = true;
                    viewer._autoFit = "fitToWidth";
                    scaleValue = (pageContainer.width() / ((visibleCanvas.width / calculatedDpr) / viewer.zoomScale));
                } else if (scale === "fitToPage") {
                    viewer._allowResize = true;
                    viewer._autoFit = "fitToPage";
                    scaleValue = (pageContainer.height() / ((visibleCanvas.height / calculatedDpr) / viewer.zoomScale));
                } else if (scale && scale.toString().match(/^[0-9]+%?$/)) {
                    scale = parseInt(scale.replace('%', ''), 10) / 100;
                    scaleValue = scale;
                } else {
                    preventZoom = isNaN(scale);
                }

                if (!preventZoom) {
                    preventZoom = scale < viewerOptions.zoomMin || scale > viewerOptions.zoomMax;
                }

                if (preventZoom || viewer.trigger(ZOOMSTART, { scale: scale })) {
                    return;
                }

                if (options.updateComboBox && viewer.toolbar)
                {
                    viewer._updateZoomComboBox(scale);
                }

                return scaleValue;
            },

            _triggerZoomEnd: function(scale) {
                var that = this,
                    viewer = that.viewer;

                viewer.trigger(ZOOMEND, { scale: scale });
            }
        });

        var PrintCommand = Command.extend({
            init: function(options) {
                Command.fn.init.call(this, options);
            },
            exec: function() {
                var that = this;

                 if (!that.viewer.document) {
                    that.viewer._triggerError({
                        message: this.errorMessages.notFound
                    });
                    return;
                }

                progress(that.viewer.pageContainer, true);
                that._renderPrintContainer();
                that._loadAllPages().then(that.processAfterRender.bind(that));
            },
            _renderPrintContainer: function() {
                this.printContainer = $("<div></div>");
            },
            _loadAllPages: function() {
                var that = this;
                var pages = that.viewer.pages;
                var loadPromises = [];
                var renderPromises = [];
                var promise = $.Deferred();

                that._originalScale = that.viewer.zoom();

                function getRenderPromise(page) {
                    renderPromises.push(page._renderPromise);
                }

                for (var i = 0; i < pages.length; i++) {
                    loadPromises.push(pages[i].load(3, true).then(getRenderPromise));
                }

                Promise.all(loadPromises).then(function() {
                    promise.resolve(renderPromises);
                });

                return promise;
            },
            processAfterRender: function(renderPromises) {
                var that = this;

                Promise.all(renderPromises).then(function() {
                    that._renderPrintPages();
                    setTimeout(function() {
                        that._printDocument();
                        that.viewer.zoom(that._originalScale);
                        progress(that.viewer.pageContainer, false);
                        delete that._originalScale;
                    }, 0);
                });
            },
            _renderPrintPages: function() {
                var pages = this.viewer.pages;

                for (var i = 0; i < pages.length; i++) {
                    this._renderPrintImage(pages[i]);
                }
             },
            _renderPrintImage: function(page) {
                var canvas = page.canvas;
                var div = $("<div></div>");

                var img = "<img src='" + canvas.toDataURL() + "' width='" + page.width + "px' height='" + page.height + "px' />";

                div.append(img);

                this.printContainer.append(div);
            },
            _printDocument: function() {
                var that = this;
                var pages = that.viewer.pages;
                var width = pages[0].width;
                var height = pages[0].height;
                var myWindow = window.open('','','innerWidth=' + width + ',innerHeight=' + height + 'location=no,titlebar=no,toolbar=no');
                var browser = kendo.support.browser;

                if (!myWindow) {
                    that.viewer._triggerError({
                        message: that.errorMessages.popupBlocked
                    });
                    return;
                }

                myWindow.document.write(that.printContainer.html());
                myWindow.document.close();
                myWindow.focus();
                myWindow.print();

                if (!browser.chrome || browser.chromiumEdge) {
                    myWindow.close();
                } else {
                    $(myWindow.document).find("body").on("mousemove", function() {
                        myWindow.close();
                    });
                }
            }
        });

        extend(kendo.pdfviewer, {
            OpenCommand: OpenCommand,
            PageChangeCommand: PageChangeCommand,
            DownloadCommand: DownloadCommand,
            EnableSelectionCommand: EnableSelectionCommand,
            EnablePanCommand: EnablePanCommand,
            ExportCommand: ExportCommand,
            PrintCommand: PrintCommand,
            OpenSearchCommand: OpenSearchCommand,
            ZoomCommand: ZoomCommand
        });

    })(window.kendo.jQuery);

    var __meta__ = {
        id: "pdfviewer",
        name: "PDFViewer",
        category: "web",
        description: "PDFViewer to display pdfs in the browser",
        depends: ["core", "window", "dialog", "toolbar", "mobile.scroller", "upload", "combobox", "drawing", "binder", "dropdownlist", "numerictextbox", "textbox"]
    };

    (function($, undefined$1) {
        var NS = ".kendoPDFViewer",
            kendo = window.kendo,
            ui = kendo.ui,
            extend = $.extend,
            drawing = kendo.drawing,
            keys = $.extend({ PLUS: 187, MINUS: 189, ZERO: 48, NUMPAD_ZERO: 96 }, kendo.keys),
            Page,
            Widget = ui.Widget,
            progress = kendo.ui.progress,
            SCROLL = "scroll",
            RENDER = "render",
            OPEN = "open",
            ERROR = "error",
            KEYDOWN = "keydown" + NS,
            MOUSEWHEEL = "DOMMouseScroll" + NS + " mousewheel" + NS,
            UPDATE = "update",
            ZOOM_SCALE = 1.25,
            PAGE_CHANGE = "pagechange",
            ZOOMSTART = "zoomStart",
            ZOOMEND = "zoomEnd",
            ZOOMCOMMAND = "ZoomCommand",
            WHITECOLOR = "#ffffff",
            TABINDEX = "tabindex",
            CLICK = "click",
            CHANGE = "change",
            TOGGLE = "toggle",
            PROCESSORS = {
                pdfjs: "pdfjs",
                dpl: "dpl"
            },
            styles = {
                viewer: "k-pdf-viewer k-widget",
                scroller: "k-canvas k-list-scroller",
                enableTextSelection: "k-enable-text-select",
                enablePanning: "k-enable-panning",
                highlightClass: "k-search-highlight",
                charClass: "k-text-char"
            },
            PREDEFINED_ZOOM_VALUES = {
                auto: "auto",
                actual: "actual",
                fitToWidth: "fitToWidth",
                fitToPage: "fitToPage"
            };

        var PDFViewer = Widget.extend({
            init: function(element, options) {
                var that = this;

                Widget.fn.init.call(that, element, kendo.deepExtend({}, this.options, options));

                that._processMessages();

                that._wrapper();

                if (that.options.toolbar) {
                    that._renderToolbar();
                }

                that._initProcessor(options || {});
                that._renderPageContainer();
                that._loadDocument();

                kendo.notify(that, kendo.ui);
            },

            events: [
                RENDER,
                OPEN,
                ERROR,
                ZOOMSTART,
                ZOOMEND
            ],

            options: {
                name: "PDFViewer",
                view: {
                    type: "canvas"
                },
                pdfjsProcessing: {
                    file: null
                },
                dplProcessing: {
                    read: {
                        url: null,
                        type: "GET",
                        dataType: "json",
                        pageField: "pageNumber"
                    },
                    upload: {
                        url: null,
                        saveField: "file"
                    },
                    download: {
                        url: null
                    },
                    loadOnDemand: false
                },
                toolbar: {
                    items: []
                },
                width: 1000,
                height: 1200,
                page: 1,
                defaultPageSize: {
                    width: 794,
                    height: 1123
                },
                scale: null,
                zoomMin: 0.5,
                zoomMax: 4,
                zoomRate: 0.25,
                messages: {
                    defaultFileName: "Document",
                    toolbar: {
                        zoom: {
                            zoomLevel: "zoom level",
                            zoomOut: "Zoom Out",
                            zoomIn: "Zoom In",
                            actualWidth: "Actual Width",
                            autoWidth: "Automatic Width",
                            fitToWidth: "Fit to Width",
                            fitToPage: "Fit to Page"
                        },
                        open: "Open",
                        exportAs: "Export",
                        download: "Download",
                        pager: {
                            first: "Go to the first page",
                            previous: "Go to the previous page",
                            next: "Go to the next page",
                            last: "Go to the last page",
                            of: " of {0} ",
                            page: "page",
                            pages: "pages"
                        },
                        print: "Print",
                        toggleSelection: "Enable Selection",
                        togglePan: "Enable Panning",
                        search: "Search"
                    },
                    errorMessages: {
                        notSupported: "Only pdf files allowed.",
                        parseError: "PDF file fails to process.",
                        notFound: "File is not found.",
                        popupBlocked: "Popup is blocked."
                    },
                    dialogs: {
                        exportAsDialog: {
                            title: "Export...",
                            defaultFileName: "Document",
                            pdf: "Portable Document Format (.pdf)",
                            png: "Portable Network Graphics (.png)",
                            svg: "Scalable Vector Graphics (.svg)",
                            labels: {
                                fileName: "File name",
                                saveAsType: "Save as",
                                page: "Page"
                            }
                        },
                        okText: "OK",
                        save: "Save",
                        cancel: "Cancel",
                        search: {
                            inputLabel: "Search Text",
                            matchCase: "Match Case",
                            next: "Next Match",
                            previous: "Previous Match",
                            close: "Close",
                            of: "of",
                            dragHandle: "Drag search"
                        }
                    }
                }
            },

            defaultTools: {
                pager: {
                    name: "pager",
                    command: "PageChangeCommand"
                },
                spacer: { type: "spacer" },
                zoomInOut: {
                    type: "buttonGroup",
                    attributes: { "class": "k-zoom-in-out-group" },
                    buttons: [
                        { type: "button", icon: "zoom-out", name: "zoomOut", command: "ZoomCommand", showText: "overflow", options: "{ \"zoomOut\": true, \"updateComboBox\": true }", fillMode: "flat" },
                        { type: "button", icon: "zoom-in", name: "zoomIn", command: "ZoomCommand", showText: "overflow", options: "{ \"zoomIn\": true, \"updateComboBox\": true }", fillMode: "flat" } ]
                },
                zoom: {
                    type: "component",
                    name: "zoom",
                    command: "ZoomCommand",
                    overflow: "never",
                    component: "ComboBox",
                    data: [50, 100, 150, 200, 300, 400],
                    componentOptions: {
                        enable: false,
                        dataTextField: "text",
                        dataValueField: "percent",
                        valuePrimitive: true,
                        clearOnEscape: false,
                        commandOn: "change"
                    }
                },
                toggleSelection: {
                    type: "buttonGroup",
                    attributes: { "class": "k-toggle-selection-group" },
                    buttons: [
                        {
                            togglable: true,
                            command: "EnableSelectionCommand",
                            icon: "cursor",
                            showText: "overflow",
                            name: "toggleSelection",
                            group: "toggle-pan",
                            fillMode: "flat"
                        }, {
                            togglable: true,
                            command: "EnablePanCommand",
                            icon: "hand",
                            showText: "overflow",
                            name: "togglePan",
                            group: "toggle-pan",
                            selected: true,
                            fillMode: "flat"
                        }
                    ]
                },
                spacer2: { type: "spacer" },
                search: {
                    type: "button",
                    command: "OpenSearchCommand",
                    icon: "search",
                    name: "search",
                    showText: "overflow",
                    enable: false,
                    fillMode: "flat"
                },
                open: {
                    type: "button",
                    showText: "overflow",
                    name: "open",
                    icon: "folder-open",
                    command: "OpenCommand",
                    fillMode: "flat"
                },
                download: {
                    type: "button",
                    showText: "overflow",
                    name: "download",
                    icon: "download",
                    command: "DownloadCommand",
                    enable: false,
                    fillMode: "flat"
                },
                print: {
                    type: "button",
                    showText: "overflow",
                    name: "print",
                    icon: "print",
                    command: "PrintCommand",
                    enable: false,
                    fillMode: "flat"
                }
            },

            exportAsTool: {
                exportAs: { type: "button", showText: "overflow", name: "exportAs", icon: "image-export", command: "ExportCommand", fillMode: "flat" }
            },

            pagerTools: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { type: "button", icon: "arrow-end-left", name: "first", showText: "overflow", options: "{ \"value\": \"first\" }", fillMode: "flat", attributes: { class: "k-first-link" } },
                        { type: "button", icon: "arrow-60-left", name: "previous", showText: "overflow", options: "{ \"value\": \"prev\" }", fillMode: "flat", attributes: { class: "k-prev-link" }, rounded: "none" } ]
                },
                {
                    type: "component",
                    component: "TextBox",
                    name: "page",
                    attributes: { class: "k-viewer-pager-input" },
                    element: "<input id='page-input'/>",
                    overflow: "never",
                    componentOptions: {
                        commandOn: "change"
                    }
                },
                {
                    overflow: "never",
                    template: function(data) {
                        return "<label for='page-input'>" + data.componentOptions.messages.of + " <span id='total-page'></span> " + data.componentOptions.messages.pages + "</label>";
                    },
                    componentOptions: {
                        messages: {
                            of: "of",
                            pages: "pages"
                        }
                    }
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { type: "button", icon: "arrow-60-right", name: "next", showText: "overflow", options: "{ \"value\": \"next\" }", fillMode: "flat", attributes: { class: "k-next-link" }, rounded: "none" },
                        { type: "button", icon: "arrow-end-right", name: "last", showText: "overflow", options: "{ \"value\": \"last\" }", fillMode: "flat", attributes: { class: "k-last-link" } }
                    ]
                } ],

            _processMessages: function() {
                var messages = this.options.messages.toolbar,
                    zoom = messages.zoom,
                    pager = messages.pager;

                if ($.isPlainObject(zoom)) {
                    this.options.messages.toolbar = $.extend({}, this.options.messages.toolbar, zoom);
                    this.options.messages.toolbar.zoom = zoom.zoomLevel || this.options.messages.toolbar.zoom;
                }

                if ($.isPlainObject(pager)) {
                    this.options.messages.toolbar = $.extend({}, this.options.messages.toolbar, pager);
                }
            },

            _wrapper: function() {
                var that = this,
                    options = that.options;

                that.wrapper = that.element;

                that.wrapper
                        .width(options.width)
                        .height(options.height)
                        .addClass(styles.viewer)
                        .on(KEYDOWN, that._keydown.bind(that));

                that._allowResize = that.options.scale === null;
                that._autoZoomScale = ZOOM_SCALE;
                that.zoomScale = that.options.scale || that._autoZoomScale;

                that._resizeHandler = kendo.onResize(function() {
                    that.resize();
                });

                that._pageNum = that.options.page;
            },

            _keydown: function(e) {
                var plusShortcuts = [keys.PLUS, keys.NUMPAD_PLUS],
                    minusShortcuts = [keys.MINUS, keys.NUMPAD_MINUS],
                    zeroShortcuts = [keys.ZERO, keys.NUMPAD_ZERO],
                    shouldExecute = false,
                    args = {
                        command: ZOOMCOMMAND,
                        options: { updateComboBox: true }
                    };

                if (!e.ctrlKey || this._blankPage || this.processingLib === PROCESSORS.dpl) {
                    return;
                }

                if (plusShortcuts.includes(e.keyCode)) {
                    args.options.zoomIn = true;
                    shouldExecute = true;
                } else if (minusShortcuts.includes(e.keyCode)) {
                    args.options.zoomOut = true;
                    shouldExecute = true;
                } else if (zeroShortcuts.includes(e.keyCode)) {
                    args.options.value = ZOOM_SCALE;
                    shouldExecute = true;
                }

                if (shouldExecute) {
                    this.execute(args);
                    e.preventDefault();
                }
            },

            _initProcessor: function(options) {
                var that = this,
                    processingOptions;

                processingOptions = options.dplProcessing ? that.options.dplProcessing : that.options.pdfjsProcessing;
                that.processingLib = options.dplProcessing ? PROCESSORS.dpl : PROCESSORS.pdfjs;

                that.processor = new kendo.pdfviewer[that.processingLib].processor(processingOptions, that);
                Page = kendo.pdfviewer[that.processingLib].Page;
            },

            _renderToolbar: function() {
                var that = this,
                    options = that.options,
                    toolbarOptions = extend({}, options.toolbar),
                    tools = toolbarOptions.items && toolbarOptions.items.length ? toolbarOptions.items : Object.keys(that.defaultTools);

                tools = that._processTools(tools);

                toolbarOptions = {
                    defaultTools: $.extend({}, that.defaultTools, that.exportAsTool),
                    parentMessages: options.messages.toolbar,
                    tools: tools,
                    resizable: true
                };

                var toolbarElement = $("<div />");
                toolbarElement.appendTo(that.element);
                that.toolbar = new kendo.ui.ToolBar(toolbarElement, toolbarOptions);
                that.options.toolbar = that.toolbar.options;

                that.toolbar.bind(TOGGLE, that._toolbarClick.bind(that));
                that.toolbar.bind(CLICK, that._toolbarClick.bind(that));
                that.toolbar.bind(CHANGE, that._toolbarClick.bind(that));

                that.bind({
                    update: that._updateToolbar.bind(that)
                });

                return that.toolbar;
            },

            _processTools: function(tools) {
                var that = this,
                    messages = that.options.messages.toolbar;

                tools = tools.flatMap(function (t) {
                    if (t === "zoom") {
                        t = that.defaultTools.zoom;
                    } else if (t === "pager") {
                        t = that.defaultTools.pager;
                    }

                    if (t.name === "zoom") {
                        t = $.extend({}, that.defaultTools.zoom, t);

                        var zoomLevels = [{
                            percent: PREDEFINED_ZOOM_VALUES.auto,
                            text: messages.autoWidth
                        }, {
                            percent: PREDEFINED_ZOOM_VALUES.actual,
                            text: messages.actualWidth
                        }, {
                            percent: PREDEFINED_ZOOM_VALUES.fitToWidth,
                            text: messages.fitToWidth
                        }, {
                            percent: PREDEFINED_ZOOM_VALUES.fitToPage,
                            text: messages.fitToPage
                        }];

                        // eslint-disable-next-line
                        var comboOptions = t.data.map(function (i) { return { percent: i, text: i + "%" } });
                        var value = that.options.scale ? that.options.scale * 100 + "%" : "auto";

                        zoomLevels = zoomLevels.concat(comboOptions);
                        t.componentOptions.dataSource = zoomLevels;
                        t.componentOptions.value = value;
                    } else if (t.name === "pager") {
                        var pagerTools = that.pagerTools;

                        that.pager = true;

                        t = pagerTools.map(function (p) {
                            if (p.buttons) {
                                p.buttons = p.buttons.map(function (b) {
                                    b.command = t.command;
                                    return b;
                                });
                            } else {
                                p.command = t.command;
                            }

                            return p;
                        });
                    }

                    return t;
                });

                return tools;
            },

            _updateToolbar: function(e) {
                var pageOptions = {
                        page: e.page || 1,
                        total: e.total || 1
                    },
                    toolbar = this.toolbar,
                    toolbarEl = toolbar.element,
                    zoomCombo = toolbarEl.find("[data-command=ZoomCommand][data-role=combobox]").data("kendoComboBox"),
                    toFocus = toolbarEl.find(".k-focus");

                if (toFocus.length === 0) {
                    toFocus = toolbarEl.find("[tabindex=0]").first();

                    if (toFocus.length === 0) {
                        toFocus = toolbar._getAllItems().first();
                    }
                }

                if (zoomCombo) {
                    zoomCombo.enable(!e.isBlank);
                    if (e.action === "zoom") {
                        this._updateZoomComboBox(e.zoom);
                    }
                }

                if ((e.action === "pagechange" || e.isBlank) && this.pager) {
                    this._updatePager(pageOptions);
                }

                this._updateOnBlank(e.isBlank);

                toolbar._resetTabIndex(toFocus);
            },

            _updateOnBlank: function(isBlank) {
                var toolbar = this.toolbar,
                    toolbarEl = toolbar.element;

                toolbar.enable(toolbarEl.find(".k-toggle-selection-group"), !isBlank);
                toolbar.enable(toolbarEl.find(".k-zoom-in-out-group"), !isBlank);

                toolbar.enable(toolbarEl.find("[data-command='OpenSearchCommand']"), !isBlank);
                toolbar.enable(toolbarEl.find("[data-command='DownloadCommand']"), !isBlank);
                toolbar.enable(toolbarEl.find("[data-command='PrintCommand']"), !isBlank);
            },

            _updatePager: function(options) {
                var toolbarEl = this.toolbar.element,
                    textBox = toolbarEl.find("#page-input").data("kendoTextBox"),
                    totalPagesSpan = toolbarEl.find("#total-page");

                if (textBox && options.page) {
                    textBox.value(options.page);
                }

                if (totalPagesSpan.length && options.total) {
                    totalPagesSpan.text(options.total);
                }

                this._togglePagerDisabledClass(options);
            },

            _togglePagerDisabledClass: function(options) {
                var toolbar = this.toolbar,
                    toolbarEl = toolbar.element,
                    total = !options.total,
                    prevFirst = toolbarEl.find(".k-prev-link").closest(".k-button-group"),
                    nextLast = toolbarEl.find(".k-next-link").closest(".k-button-group"),
                    textBox = toolbarEl.find("#page-input").data("kendoTextBox");

                if (prevFirst.length) {
                    toolbar.enable(prevFirst, total || options.page !== 1);
                }
                if (nextLast.length) {
                    toolbar.enable(nextLast, total || options.page !== options.total);
                }

                if (textBox) {
                    textBox.enable(options.total > 1);
                }
            },

            _updateZoomComboBox: function(value) {
                var isPredefined = value === PREDEFINED_ZOOM_VALUES.auto ||
                    value === PREDEFINED_ZOOM_VALUES.actual ||
                    value === PREDEFINED_ZOOM_VALUES.fitToPage ||
                    value === PREDEFINED_ZOOM_VALUES.fitToWidth,
                    zoomCombo = this.toolbar.element.find("[data-command=ZoomCommand][data-role=combobox]").data("kendoComboBox");

                if (!isPredefined) {
                    value = Math.round(value * 100) + '%';
                }

                if (zoomCombo) {
                    zoomCombo.value(value);
                }
            },

            _toolbarClick: function(ev) {
                var command = $(ev.target).data("command"),
                    options = $(ev.target).data("options");

                options = extend({}, { value: $(ev.target).val() }, options);

                if (!command) {
                    return;
                }

                this.execute({
                    command: command,
                    options: options
                });
            },

            _initErrorDialog: function(options) {
                var that = this;

                if (!that._errorDialog) {
                    options = extend(options, {
                        messages: that.options.messages
                    });
                    var dialogInstance = new kendo.pdfviewer.dialogs.ErrorDialog(options);
                    that._errorDialog = dialogInstance._dialog;
                }
                return that._errorDialog;
            },

            _renderPageContainer: function() {
                var that = this;

                if (!that.pageContainer) {
                    that.pageContainer = $("<div />");
                    that.pageContainer.addClass(styles.scroller);
                    that.pageContainer.attr(TABINDEX, 0);
                    that.wrapper.append(that.pageContainer);
                }
            },

            _triggerError: function(options) {
                var dialog = this._initErrorDialog();
                extend(options, {
                    dialog: dialog
                });
                if (this.pageContainer) {
                    progress(this.pageContainer, false);
                }

                if (this.trigger(ERROR, options))
                {
                    return;
                }

                dialog.open().content(options.message);
            },

            _renderPages: function() {
                var that = this,
                    document = that.document,
                    pagesData;

                that.pages = [];

                if (!document || !document.total) {
                    that._renderBlankPage();
                    return;
                }

                pagesData = document.pages;

                for (var i = 1; i <= document.total; i++) {
                    var viewerPage,
                        pageData = {
                            processor: that.processor,
                            number: i
                        };

                    if (pagesData && pagesData.length) {
                        pageData = extend(pageData, pagesData[i - 1]);
                    }

                    viewerPage = new Page(pageData, that);
                    that.pages.push(viewerPage);
                    that.pageContainer.append(viewerPage.element);
                }

                that._attachContainerEvents();
                that._getVisiblePagesCount();
            },

            _renderBlankPage: function() {
                this._blankPage = new Page(this.options.defaultPageSize, this);

                this.pageContainer.append(this._blankPage.element);

                this.trigger(UPDATE, { isBlank: true });
            },

            _resize: function() {
                var that = this,
                    containerWidth,
                    ratio;

                if (!that._allowResize) {
                    return;
                }

                if (!that.pages || !that.pages.length) {
                    if (that._blankPage) {
                        ratio = containerWidth / that._blankPage.element.width();
                        that._blankPage.resize(ratio);
                    }
                    return;
                }

                if (that.toolbar) {
                    that.toolbar.resize(true);
                }

                if (that._resizeHandler) {
                    clearTimeout(that._resizeHandler);
                }
                that._resizeHandler = setTimeout(that._resizePages.bind(that), 100);
            },

            _resizePages: function() {
                var that = this,
                    containerWidth = that.pageContainer[0].clientWidth,
                    ratio = 0;

                that.pages.forEach(function(page) {
                    var currentRatio = containerWidth / page.element.width();

                    if (currentRatio > ratio) {
                        ratio = currentRatio;
                    }
                });

                if (that._autoFit) {
                    that.zoom(that._autoFit, true);
                    return;
                }

                ratio = Math.min(Math.max(ratio, that.options.zoomMin), ZOOM_SCALE);
                if (ratio != that.zoomScale) {
                    that.zoom(ratio, true);
                    that.zoomScale = ratio;
                    that._allowResize = true;
                }
            },

            _attachContainerEvents: function() {
                var that = this;

                that._wheel = kendo.throttle(
                    that._wheel.bind(that),
                    300
                );

                if (that.processingLib !== PROCESSORS.dpl) {
                    that.pageContainer.on(MOUSEWHEEL, function(e) {
                        if (!e.ctrlKey) {
                            return;
                        }

                        if (document.activeElement !== that.pageContainer[0]) {
                            that.pageContainer.trigger("focus");
                        }

                        that._wheel(e);
                        e.preventDefault();
                    });
                }

                that._scroller = new kendo.mobile.ui.Scroller(that.pageContainer, {
                    zoom: false,
                    elastic: true
                });

                that._scroller.scrollElement.addClass(styles.enablePanning);
                that._scroller.bind(SCROLL, that._scroll.bind(this));
            },

            _scroll: function(e) {
                var that = this,
                    containerScrollHeight = that.pageContainer[0].scrollHeight,
                    containerHeight = that.pageContainer.height(),
                    containerScrollTop = e.scrollTop,
                    containerOffsetTop = that.pageContainer.offset().top,
                    total = that.pages.length,
                    pageNum = that._pageNum,
                    pageIndex = pageNum - 1,
                    pageToLoadNum = pageNum,
                    pageToLoad,
                    currentPage, currentPageTop, currentPageHeight,
                    previousPage, prevPageTop, prevPageHeight,
                    scrollDirection = containerScrollTop - that._prevScrollTop > 0 ? 1 : -1;

                    if (that._preventScroll || !total) {
                        that._preventScroll = false;
                        return;
                    }

                    that._scrollingStarted = true;

                    currentPage = that.pages[pageIndex];
                    currentPageTop = currentPage.element.offset().top - containerOffsetTop;
                    currentPageHeight = currentPage.element.height();

                    if (scrollDirection == -1 && that.pages[pageIndex + scrollDirection]) {
                        previousPage = that.pages[pageIndex - that._visiblePagesCount] || that.pages[pageIndex + scrollDirection];
                        prevPageTop = previousPage.element.offset().top - containerOffsetTop;
                        prevPageHeight = previousPage.element.height();
                    }

                    if (Math.abs(containerScrollTop - (that._prevScrollTop || 0)) > containerHeight * that.zoomScale) {
                        pageToLoadNum = Math.floor(containerScrollTop * (1 / (containerScrollHeight / total))) + 1;
                    } else if (currentPageTop < 0 && Math.abs(currentPageTop) >= currentPageHeight / 2 && scrollDirection === 1) {
                        pageToLoadNum++;
                    } else if (previousPage && Math.abs(prevPageTop) <= prevPageHeight / 2) {
                        pageToLoadNum--;
                    }

                    if (pageNum !== pageToLoadNum && pageToLoadNum >= 1 && pageToLoadNum <= total) {
                        pageToLoad = that.pages[pageToLoadNum - 1].element;

                        if (pageToLoad.offset().top > containerHeight) {
                            return;
                        }

                        that._pageNum = pageToLoadNum;
                        that._loadVisiblePages();

                        that.trigger(UPDATE, { action: PAGE_CHANGE, page: pageToLoadNum, total: total });
                    }

                    that._prevScrollTop = containerScrollTop;
            },

            _wheel: function(e) {
                var originalEvent = e.originalEvent,
                    delta = originalEvent.wheelDelta ? -originalEvent.wheelDelta : originalEvent.detail,
                    zoomIn = delta < 0;

                this.execute({
                    command: ZOOMCOMMAND,
                    options: {
                        zoomIn: zoomIn,
                        zoomOut: !zoomIn,
                        updateComboBox: true
                    }
                });

                e.preventDefault();
            },

            zoom: function(scale, preventComboBoxChange) {
                var that = this;
                if (!scale) {
                    return that.zoomScale;
                }

                return that.execute({
                    command: ZOOMCOMMAND,
                    options: {
                        value: scale,
                        updateComboBox: !preventComboBoxChange
                    }
                });
            },

            execute: function(options) {
                var commandOptions = extend({ viewer: this }, options.options);
                var command = new kendo.pdfviewer[options.command](commandOptions);
                return command.exec();
            },

            _loadDocument: function() {
                var that = this;
                var page = that.options.page;

                progress(that.pageContainer, true);
                that.processor.fetchDocument().done(function(document) {
                    that._clearPages();
                    that.document = document;

                    that._renderPages();
                    that.resize(true);

                    if (document) {
                        page = page >= 1 && page <= document.total ? page : 1;
                        that.activatePage(page);
                    }

                    progress(that.pageContainer, false);
                });
            },

            loadPage: function(number) {
                var page = this.pages && this.pages[number - 1];

                if (page) {
                    return page.load(this.zoomScale);
                }
            },

            activatePage: function(number) {
                var page = this.pages && this.pages[number - 1],
                    scroller = this._scroller,
                    scrollerTopPosition,
                    scrollerTopOffset,
                    pageTopOffset,
                    pageMargin;

                if (!page) {
                    return;
                }

                scrollerTopPosition = scroller.scrollTop;
                scrollerTopOffset = scroller.element.offset().top;
                pageTopOffset = page.element.offset().top;
                pageMargin = !this._autoFit ? parseInt(page.element.css("marginTop"), 10) : 0;

                this._pageNum = number;
                this._loadVisiblePages();

                this._preventScroll = true;

                this._scroller.scrollTo(0, -scrollerTopPosition - pageTopOffset + scrollerTopOffset + pageMargin);
                this.trigger(UPDATE, { action: PAGE_CHANGE, page: number, total: this.pages.length });
            },

            _getVisiblePagesCount: function() {
                var that = this,
                    loadedPagesHeight = 0,
                    updatedVisiblePagesCount = 0,
                    containerHeight = that.pageContainer[0].clientHeight,
                    index = 0;

                while (loadedPagesHeight <= containerHeight && index < that.pages.length)
                {
                    loadedPagesHeight += that.pages[index].element.height();
                    updatedVisiblePagesCount++;
                    index++;
                }

                that._visiblePagesCount = updatedVisiblePagesCount;
            },

            _loadVisiblePages: function() {
                var pagesCount = this.pages && this.pages.length,
                    minVisiblePageNum = Math.max(this._pageNum - this._visiblePagesCount, 1),
                    maxVisiblePageNum = Math.min(this._pageNum + this._visiblePagesCount, pagesCount);

                this._visiblePages = this.pages.slice(minVisiblePageNum - 1, maxVisiblePageNum);

                for (var i = minVisiblePageNum; i <= maxVisiblePageNum; i++)
                {
                    this.loadPage(i);
                }
            },

            _loadAllPages: function() {
                var pagesCount = this.pages && this.pages.length;
                var promises = [];

                for (var i = 0; i <= pagesCount; i++)
                {
                    promises.push(this.loadPage(i));
                }

                return promises;
            },

            fromFile: function(file) {
                this.zoomScale = this.options.scale || ZOOM_SCALE;
                this.zoom(this.zoomScale, true);
                this.trigger(UPDATE, { action: "zoom", zoom: this.options.scale || "auto" });

                this.processor._updateDocument(file);
                this._loadDocument();
            },

            exportImage: function(options) {
                var that = this;
                var pageNumber = options.page;
                var page = that.pages[pageNumber - 1] || that._blankPage;
                var rootGroup = new drawing.Group();

                page.load();

                var background = kendo.drawing.Path.fromRect(new kendo.geometry.Rect([0, 0], [page.width, page.height]), {
                    fill: {
                        color: WHITECOLOR
                    },
                    stroke: null
                });

                progress(that.pageContainer, true);
                rootGroup.append(background, page.group);

                drawing.exportImage(rootGroup).done(function(data) {
                    progress(that.pageContainer, false);
                    kendo.saveAs({
                        dataURI: data,
                        fileName: options.fileName,
                        proxyURL: options.proxyURL || "",
                        forceProxy: options.forceProxy,
                        proxyTarget: options.proxyTarget
                    });
                });
            },

            exportSVG: function(options) {
                var that = this;
                var pageNumber = options.page;
                var page = that.pages[pageNumber - 1] || that._blankPage;

                progress(that.pageContainer, true);

                page.load();

                drawing.exportSVG(page.group).done(function(data) {
                    progress(that.pageContainer, false);
                    kendo.saveAs({
                        dataURI: data,
                        fileName: options.fileName,
                        proxyURL: options.proxyURL || "",
                        forceProxy: options.forceProxy,
                        proxyTarget: options.proxyTarget
                    });
                });
            },

            setOptions: function(options)
            {
                var that = this;

                if (options.pdfjsProcessing || options.dplProcessing) {
                    that._initProcessor(options || {});
                }

                options = $.extend(that.options, options);

                Widget.fn.setOptions.call(that, options);

                if (options.page) {
                    that._pageNum = options.page;
                    that.activatePage(options.page);
                }

                if (options.width) {
                    that.element.width(options.width);
                }

                if (options.height) {
                    that.element.height(options.height);
                }
            },

            destroy: function()
            {
                if (this._resizeHandler)
                {
                    kendo.unbindResize(this._resizeHandler);
                }

                //destroy nested components
                if (this._errorDialog) {
                    this._errorDialog.destroy();
                }

                if (this._saveDialog) {
                    this._saveDialog.destroy();
                }

                if (this._upload) {
                    this._upload.destroy();
                }

                if (this.toolbar) {
                    this.toolbar.unbind();
                    this.toolbar.destroy();
                    this.toolbar = null;
                }

                if (this.pages && this.pages.length) {
                    this.pages.forEach(function(page) {
                        page.destroy();
                    });
                    this.pages = [];
                }

                if (this._scroller) {
                    this._scroller.unbind();
                    this._scroller.destroy();
                }
                this.pageContainer.off(NS);

                Widget.fn.destroy.call(this);
            },

            _clearPages: function() {
                this.pages = [];
                this.document = null;
                this._pageNum = 1;

                this.pageContainer.off(NS);
                this.pageContainer.empty();

                if (this._scroller)
                {
                    this._scroller.reset();
                    this._scroller.unbind();
                    this._scroller.destroy();
                }
            },

            _toggleSelection: function(enable) {
                var that = this;

                if (enable === undefined$1) {
                    enable = true;
                }

                that._scroller.userEvents._shouldNotMove = enable;

                that._scroller.scrollElement.toggleClass(styles.enableTextSelection, enable);
                that._scroller.scrollElement.toggleClass(styles.enablePanning, !enable);
            },


            _initSearchDOM: function() {
                var that = this;
                var promise = new Promise(function(resolve) {
                    Promise.all(that._loadAllPages()).then(function() {
                        that._searchDOM = new kendo.pdfviewer.SearchDOM({
                            target: that._getTextLayers(),
                            highlightClass: styles.highlightClass,
                            charClass: styles.charClass
                        });

                        resolve();
                    });
                });

                return promise;
            },

            _getTextLayers: function() {
                return this.pages.map(function(page) {
                    return page.textLayer;
                });
            }
        });

        ui.plugin(PDFViewer);
    })(window.kendo.jQuery);

}));
